import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { StoreContext } from "../context/store-context";

// comps
import Layout from "../components/Layout";
import { LineItem, Section, SectionContent, Title, Seo } from "../components";
import { StyledButton } from "../styles/Buttons";
import { Container } from "../styles/Container";

// utils
import { formatPrice } from "../utils/format-price";
import {
  table,
  wrap,
  totals,
  grandTotal,
  summary,
  collapseColumn,
  labelColumn,
  imageHeader,
  productHeader,
  emptyStateContainer,
  emptyStateHeading,
  emptyStateLink
} from "./cart.module.css";

const Cart = () => {
  const { checkout, loading } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const handleCheckout = () => {
    window.open(checkout.webUrl);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Seo title="Cart" />
      <Section>
        <SectionContent>
          <div className={wrap}>
            {emptyCart ? (
              <div className={emptyStateContainer}>
                <h4>Your cart is empty</h4>
                <p>
                  Looks like you haven’t found anything yet. We understand that
                  sometimes it’s hard to choose — maybe this helps:
                </p>
                <Link to="/" className="global_btn">
                  View our favourites
                </Link>
              </div>
            ) : (
              <>
                <Title title="Cart" />
                <table className={table}>
                  <thead>
                    <tr>
                      <th className={imageHeader}>Image</th>
                      <th className={productHeader}>Product</th>
                      <th className={collapseColumn}>Price</th>
                      <th>Qty.</th>
                      <th className={[totals, collapseColumn].join(" ")}>
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkout.lineItems.map(item => (
                      <LineItem item={item} key={item.id} />
                    ))}
                    <tr className={summary}>
                      <td className={collapseColumn}></td>
                      <td className={collapseColumn}></td>
                      <td className={collapseColumn}></td>
                      <td className={labelColumn}>Shipping</td>
                      <td className={totals}>Calculated at checkout</td>
                    </tr>
                    <tr className={grandTotal}>
                      <td className={collapseColumn}></td>
                      <td className={collapseColumn}></td>
                      <td className={collapseColumn}></td>
                      <td className={labelColumn}>Total Price</td>
                      <td className={totals}>
                        {formatPrice(
                          checkout.totalPriceV2.currencyCode,
                          checkout.totalPriceV2.amount
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <button
                  onClick={handleCheckout}
                  disabled={loading}
                  className="global_btn global_btn--inverse global_btn--checkout"
                >
                  Checkout
                </button>
              </>
            )}
          </div>
          <Container>
            <div className="global_btn-center">
              <StyledButton to="..">Continue Shopping</StyledButton>
            </div>
          </Container>
        </SectionContent>
      </Section>
    </Layout>
  );
};

const Wrapper = styled.div``;

export default Cart;
